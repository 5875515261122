import styled from "styled-components/macro"
import { Box, Heading, Paragraph } from "~styled"

const Hero = styled(Box)`
  padding-top: ${(props) => (props.adsPage ? "86px" : "230px")};
  padding-bottom: 0px;

  position: relative;
  object-fit: cover;
  object-position: ${(props) => props.position || "50% 50%"} !important;
  z-index: 1;
  @media (min-width: 768px) {
    padding-top: ${(props) => (props.adsPage ? "86px" : "200px")};
    padding-bottom: 0px;
  }
  @media (min-width: 992px) {
    padding-top: ${(props) => (props.adsPage ? "86px" : "200px")};
    padding-bottom: 00px;
  }
  @media (min-width: 1200px) {
    padding-top: ${(props) => (props.adsPage ? "86px" : "300px")};
    padding-bottom: 0px;
  }

  height: ${(props) => (props.adsPage ? "100%" : props.height || "50vh")};

  display: flex;
  align-items: ${(props) => (props.adsPage ? "center" : "flex-end")};
  color: white;
`

Hero.Subtitle = styled(Heading)`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 26px;
`
Hero.Title = styled(Heading)`
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -0.5px;
  line-height: 1.2068;
  margin-bottom: 21px;
  @media (min-width: 768px) {
    font-size: 40px;
  }
  @media (min-width: 992px) {
    font-size: 44px;
  }
`
Hero.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.875;
  margin-bottom: 10px;
`
Hero.Content = styled(Box)`
  position: relative !important;
  margin-top: 0;
  @media (min-width: 992px) {
    margin-top: 80px;
  }
`
Hero.Video = styled(Box)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 40px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  a {
    color: #b8a48d;
  }
  .video-block {
    display: flex;
    align-items: center;
    background: none;
    border: 0;
    box-shadow: none;
    text-align: left;
    &:focus {
      outline: none;
      box-shadow: none;
    }
    i {
      min-width: 50px;
      max-width: 50px;
      min-height: 50px;
      max-height: 50px;
      box-shadow: 12px 20px 50px rgba(0, 0, 0, 0.2);
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #b8a48d;
      margin-right: 20px;
      color: #fff;
    }

    .btn-text {
      color: #b8a48d;
      transition: 0.4s;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 22px;
    }

    &:hover {
      .btn-text {
        text-decoration: underline;
      }
    }
  }
`

Hero.ThemeOverlay = styled(Box)`
  &::before {
    content: "";
    position: fixed;
    opacity: 0.2;
    left: 0;
    top: 0;
    background-image: ${(props) =>
      props.image &&
      `linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, .4) 20%, rgba(0, 0, 0, .3) 40%, rgba(0, 0, 0, .4) 90%),url(${props.image})`};
    width: 100%;
    height: 100vh;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    background-attachment: fixed;
  }
  &::after {
    content: "";
    position: fixed;
    opacity: 0.2;
    left: 0;
    top: 0;
    background-color: ${(props) => (props.color ? props.color : "transparent")};
    width: 100%;
    height: 100vh;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: cover;
  }
`

Hero.Box = styled(Box)``
export default Hero
