import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import moment from "moment"
import SpecialBox from "./style"
import ContentSectionSpecialBox from "~components/ContentSpecialBox"
import Images from "~data/imageImports"

const SpecialBar = () => {
  // const to = moment("07/09/2022, 00:00 am", "MM/DD/YYYY, hh:mm a")
  // const now = moment()
  // const countdown = moment(to - now)
  // const days = countdown.format("D")
  // const hours = countdown.format("HH")
  // const minutes = countdown.format("mm")
  // const seconds = countdown.format("ss")

  // let daysLeft = parseInt(moment.duration(to.diff(now)).asDays())

  const promoImage = "./mothers-day.jpg"

  return (
    <SpecialBox>
      <ContentSectionSpecialBox
        image={
          <StaticImage
            src={promoImage}
            alt=""
            placeholder="blurred"
            layout="fullWidth"
            imgStyle={{ borderRadius: 6 }}
          />
        }
        title={`<span style="color:#ff0000">Mother's Day</span> Specials`}
        description={`We'll take care of all the details this Mother's Day!`}
        ctaText1={"See all specials"}
        ctaLink1={"/packages/mothers-day/"}
        ctaText2={""}
        ctaLink2={""}
        video={""}
      />
    </SpecialBox>
  )
}

export { SpecialBar as default }
