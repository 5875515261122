import React from "react"
import Card from "./style"
import Link from "~components/Core/Link"
import { Images } from "~data"
import moment from "moment"

export default function TestiomialCard({ title, text, image, userPosition, link, date }) {
  return (
    <Card backgroundColor="#fff">
      {/* {image && <Card.Image image={image ? image : Images.sections.avatarPlaceholder} />} */}
      <Card.Body fontColor="#262729">
        <Card.Text>{text.slice(0, 240)}...</Card.Text>
        <Card.UserBlock>
          <Card.UserInfo mr="15px" mb="15px">
            <Card.Title>
              <Link link={link} target="_blank" rel="noopener noreferrer">
                {title}
              </Link>
              <Card.Date>{moment(date).format("MMM YYYY")}</Card.Date>
            </Card.Title>
            <Card.UserPosition>{userPosition}</Card.UserPosition>
          </Card.UserInfo>
          <Card.Rating mb="15px">
            <Card.RatingSingle mr="5px" active={true}>
              <i className="fa fa-star" />
            </Card.RatingSingle>
            <Card.RatingSingle mr="5px">
              <i className="fa fa-star" />
            </Card.RatingSingle>
            <Card.RatingSingle mr="5px">
              <i className="fa fa-star" />
            </Card.RatingSingle>
            <Card.RatingSingle mr="5px">
              <i className="fa fa-star" />
            </Card.RatingSingle>
            <Card.RatingSingle mr="5px">
              <i className="fa fa-star" />
            </Card.RatingSingle>
          </Card.Rating>
        </Card.UserBlock>
      </Card.Body>
    </Card>
  )
}
