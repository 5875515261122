import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Category from "./style"
import CategoryCard from "~components/CategoryCard"
import { Link } from "~components"

import Images from "~data/imageImports"

export default function CategorySection({ ...rest }) {
  const categories = [
    { name: "medspa", slug: "med-spa", title: "Medical Spa", image: Images.essence.homepage.medspaImage },
    { name: "face", slug: "facial-treatments", title: "Facial Treatments", image: Images.essence.homepage.faceImage },
    { name: "massage", slug: "massage", title: "Massage", image: Images.essence.homepage.massageImage },
    { name: "body", slug: "body-treatments", title: "Body Treatments", image: Images.essence.homepage.bodyImage },
  ]
  return (
    <Category backgroundColor="#111111" {...rest}>
      <Category.Box mb="0" pb="0px">
        <Container>
          <Row className="align-items-center justify-content-center">
            {categories.map((category, index) => {
              return (
                <Col className="col-xl-3 col-lg-6 col-md-6 col-xs-12 col-12" key={"related-" + index}>
                  <CategoryCard to={`/services/${category.slug}/`} image={category.image} title={category.title} />
                </Col>
              )
            })}
          </Row>
        </Container>
      </Category.Box>
    </Category>
  )
}
