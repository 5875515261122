import styled from "styled-components/macro"

import { Box } from "~styled"

const SpecialBox = styled(Box)`
  align-items: start;
  /* border-bottom: solid 1px #e4e4e4; */
  background: #e5aa4b;

  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  text-align: left;

  p {
    color: black;
    font-style: italic;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
`

SpecialBox.Box = styled(Box)``
export default SpecialBox
