import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Hero from "./style"
import { useStaticQuery, graphql } from "gatsby"
import Images from "~data/imageImports"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"

var settings = {
  touchDisabled: true,
  autoplay: 3000,
  infinite: true,
  minSwipeOffset: 20,
  duration: 2000,
}

export default function HeroSection({ children, frontmatter, height, adsPage, servicePage, homePage, color }) {
  const sliderMap = homePage?.nodes.map((slide, index) => {
    let theImage = slide.frontmatter.heroImage?.childImageSharp.gatsbyImageData.images.fallback.src
    let desc = { __html: slide.frontmatter.description?.slice(0, 130).concat("...") }
    return (
      <div key={index} className="slider-content" style={{ background: `url('${theImage}') no-repeat center center` }}>
        <div className="inner">
          <h1 className="slider-title">{slide.frontmatter.title}</h1>
          <p className="slider-description">{<span dangerouslySetInnerHTML={desc} />}</p>
          {slide.frontmatter.type === "ads" && (
            <a href={`/ads${slide.fields.slug}`} className="btn btn-white rounded-pill py-3 me-auto">
              {"Learn more"}
            </a>
          )}
          {slide.frontmatter.type === "content" && (
            <a href={`/services${slide.fields.slug}`} className="btn btn-white rounded-pill py-3 me-auto">
              {"Learn more"}
            </a>
          )}
        </div>
      </div>
    )
  })

  return (
    <>
      {adsPage && (
        <>
          {console.log({ color })}
          <Hero height={height} adsPage>
            <Hero.ThemeOverlay
              color={color}
              image={frontmatter.heroImage.childImageSharp.gatsbyImageData.images.fallback.src}
            />
            <Container>
              <Row className="align-items-center justify-content-center justify-content-md-start">{children}</Row>
            </Container>
          </Hero>
        </>
      )}
      {servicePage && (
        <>
          <Hero
            height={height}
            backgroundImage={frontmatter.heroImage?.childImageSharp.gatsbyImageData.images.fallback.src}>
            <Container>
              <Row className="">
                <Col className="col-md-8 col-xs-12">
                  <Hero.Content>
                    {/* <Hero.Subtitle as="h5" fontColor="#feb856" mb="12px">
                      {frontmatter.heroTag ? frontmatter.heroTag : frontmatter.category}
                    </Hero.Subtitle> */}
                    <Hero.Title as="h1" fontColor="#fff">
                      {frontmatter.heroTitle || frontmatter.title}
                    </Hero.Title>
                  </Hero.Content>
                </Col>
              </Row>
            </Container>
          </Hero>
        </>
      )}
      {homePage && (
        <>
          <Slider
            className="slider-wrapper"
            previousButton={
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 20 30">
                <polygon
                  fill="#fff"
                  points="20 15 4.228 0 0 3.626 11.954 15 0 26.374 4.228 30"
                  transform="rotate(180 10 15)"></polygon>
              </svg>
            }
            nextButton={
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 20 30">
                <polygon
                  fill="#fff"
                  points="20 15 4.228 0 0 3.626 11.954 15 0 26.374 4.228 30"
                  transform="rotate(0 10 15)"></polygon>
              </svg>
            }
            {...settings}>
            {sliderMap}
          </Slider>
        </>
      )}
    </>
  )
}
