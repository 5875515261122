import React from "react"
import TestimonialCard from "./Components/Card"
import SectionTitle from "./Components/SectionTitle"
import Testimonial from "./style"
import { Col, Container, Row } from "react-bootstrap"
import { Box, Heading, Button, Paragraph } from "~styled"

export default function TestimonialSection(props) {
  const reviews = props.reviews
  const business = props.business
  return (
    <Box mt="80px" pb="0" mtMD="100px" pbMD="0" mtXL="100px" pbXL="0">
      <Testimonial backgroundColor="#efebe9">
        <Container>
          <Row className="justify-content-center">
            <Col className="col-lg-9 col-xl-9 col-xxl-7 text-center">
              <img src={"/images/company-google.png"} alt="Google logo" />
              <SectionTitle
                subTitle={`We Care About Our Guests Experience`}
                title={`Rated <span style="color: #ff971d">${business.rating}</span> Based on <span style="color: #1a73e8">${business.user_ratings_total}</span> Reviews`}
                subTitleProps={{ mb: "1rem", mt: "1rem" }}
                titleProps={{ mb: "10px", as: "h2" }}
                mb="50px"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            {reviews.map((item, i) => {
              return (
                <Col col="12" className="col-lg-4 col-xs-12" key={"testimonial-" + i}>
                  <TestimonialCard
                    text={item.node.text}
                    title={item.node.user.name}
                    image={item.node.user?.image_url}
                    link={item.node.url}
                    date={item.node.time}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </Testimonial>
    </Box>
  )
}
