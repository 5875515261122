import React from "react"
import { Link } from "~components"

import CategoryCard from "./style"
export default function StyledCard({ to, title, text, image }) {
  return (
    <CategoryCard
      as={Link}
      link={to ? to : "/"}
      className={`w-100`}
      style={{ borderRadius: "8px", overflow: "hidden" }}>
      <CategoryCard.Image backgroundImage={image} />
      <CategoryCard.Content>
        <CategoryCard.InnerBlock backgroundColor="rgba(0,0,0,0.8)">
          <CategoryCard.Text fontColor="#fff">{text}</CategoryCard.Text>
          <CategoryCard.Title as="h2" fontColor="#fff">
            {title}
          </CategoryCard.Title>
        </CategoryCard.InnerBlock>
      </CategoryCard.Content>
    </CategoryCard>
  )
}
