import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Link, PageWrapper, SEO } from "~components"
import ContentSectionLeft from "~components/ContentLeft"
import ContentSectionRight from "~components/ContentRight"
import CtaSection from "~components/Cta"
import FooterBlock from "~components/FooterBlock"
import FooterSimple from "~components/FooterSimple"
import HeroSection from "~components/Hero"
import IntroSection from "~components/IntroSection"
import TestimonialSection from "~components/Testimonial"
import { useSiteMetadata } from "~hooks/use-site-metadata"
import SpecialBar from "~components/SpecialBar"
import CategorySection from "~components/CategorySection"
import Images from "~data/imageImports"

// mobile-sticky-enable
const header = {
  headerClasses:
    "site-header site-header--menu-end light-header site-header--sticky position-relative site-header--menu-sep mobile-sticky-enable",
  containerFluid: false,
  darkLogo: true,
  buttonBlock: (
    <Link
      title={"Gift Cards"}
      className="btn btn-outline-secondary btn-sm rounded-pill py-3 ms-auto ms-lg-0 d-none d-xs-inline-flex"
      link="https://www.vagaro.com/Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVGOwOvJkWSUu0HWpFHcIQKdziAzzXN6MkSKz4rU2l1PnM3MEg0m2hqPQWlqNqXcA14R4IqMe4FpU2ds9IcrYPL9zxOg7Q54n04kDy63+MfHDu99ov59H8Ij505cqCz81BQN8bMWMaAbHb1TtZMNcNNSD9cEnGtY8UzntIRtke/hIaN74IzpmrSgy1UUsr9IthvlgfvKwPpOVYw0Gn2GCeJ9yuXa9X+vS+kN1VJfSGxizZv7xHtZd+Ct3dpE9VpNzge6cI16+VNesvPxgK3VO+eoaNwXwze/CLbVYlz5t4BHEKmHwTiVfLUvozETgr+SQzQ==">
      <span>
        <i className="fa fa-gift"></i>
        {"  "}Gift Cards
      </span>
    </Link>
  ),
}

export default function Homepage({ data }) {
  const siteMetadata = useSiteMetadata()
  const { homepageSlider } = data
  // console.log({ data })
  React.useEffect(() => {
    // console.log(siteMetadata)
  }, [])

  const sparty2 = "../assets/image/essence/homepage/sparty2.jpg"
  const sparty3 = "../assets/image/essence/homepage/sparty3.jpg"
  const moxi = "../assets/image/essence/homepage/moxi-event.jpg"
  const blackfriday = "../assets/image/essence/homepage/microneedling.jpg"
  const cybermonday = "../assets/image/essence/homepage/cybermonday.jpg"
  const christmasSale = "../assets/image/essence/homepage/christmasSale.jpg"
  const giftCards = "../assets/image/essence/homepage/gift-cards.jpg"
  const valentineExperience = "../assets/image/essence/homepage/valentine-experience.jpg"
  const rightImage = "../assets/image/essence/homepage/homepage-start.jpg"
  const leftImage = "../assets/image/essence/homepage/homepage-buygiftcards.jpg"
  // const yelpReviews = data?.yelpReviews?.edges
  const googleBusiness = data?.googleBusiness?.edges[0].node
  const googleReviews = data?.googleReviews?.edges
  const googleReviewsRestructured = googleReviews.map(({ node }) => {
    return {
      node: {
        id: node.id,
        rating: node.rating,
        text: node.text,
        time_created: node.time,
        url: node.author_url,
        user: {
          image_url: node.profile_photo_url,
          name: node.author_name,
          profile_url: node.profile_photo_url,
        },
      },
    }
  })
  // console.log(`googleReviewsRestructured: `, googleReviewsRestructured)

  return (
    <PageWrapper headerConfig={header}>
      <SEO
        // title={`${siteMetadata.title}`}
        // description={`${siteMetadata.description}`}
        type={`${siteMetadata.type}`}
        location="/"
        indexed="true"
        image={`${Images.essence.homepage.HomepageSpa}`}
      />
      {/* <Helmet>
        <script src="https://app.embed.im/snow.js" async defer></script>
      </Helmet> */}
      <HeroSection height={"550px"} homePage={homepageSlider} />
      {/* <SpecialBar /> */}
      <IntroSection pb="40px" pbMD="50px" pbXL="60px" pt="40px" ptMD="50px" ptXL="60px" />
      <CategorySection pb="40px" pbMD="50px" pbXL="60px" pt="40px" ptMD="50px" ptXL="60px" />

      {/* <ContentSectionRight
        image={
          <StaticImage
            src={valentineExperience}
            alt=""
            placeholder="blurred"
            layout="fullWidth"
            imgStyle={{ borderRadius: 6 }}
          />
        }
        title={`Get The Bamboo Suite!<br/>The <span style="color:#ff0000">Valentine's</span> Experience`}
        description={`Book any spa treatment with a <b>Complimentary Bamboo Suite</b> Upgrade.<br/><i>OFFER VALID: February 15-19, 2023</i></i>`}
        ctaText1={"Shop Now"}
        ctaLink1={"/ads/valentine/the-valentines-experience/"}
        ctaText2={""}
        ctaLink2={""}
        video={""}
      /> */}

      {/* <ContentSectionRight
        image={
          <StaticImage src={moxi} alt="" placeholder="blurred" layout="fullWidth" imgStyle={{ borderRadius: 6 }} />
        }
        subtitle={"Essence + Evovle"}
        title={`MOXI <b style="color:#e5aa4b;">Makeover</b>`}
        description={`We are excited to share we'll be collaborating with Evolve Beauty Bar & Spa with the latest, gold standard of skin rejuvenation, MOXI laser. MOXI is the latest way to revitalize aging skin by reducing pigment and improving the general tone and texture of the skin. Moxi is great for men and women of all skin types, as it lightens, and brightens giving you that red carpet glow.<br/><br/> <b>Date: </b>May 20th, 2023<br/><b>Time: </b>9:00 am - 1:00 pm<br/><br/><b>$700</b> INCLUDES FREE NECK AREA<br/><br/>BY APPOINTMENT ONLY! Call us at <b><a href="tel://+17737631212">773 763-1212</a></b><br/><br/><b>Book Now & SAVE $100</b> on your 2nd treatment!`}
        ctaText1={"Call us"}
        ctaLink1={"+1 (773) 763-1212"}
        ctaText2={""}
        ctaLink2={""}
        video={""}
      /> */}

      <ContentSectionRight
        image={
          <StaticImage src={sparty3} alt="" placeholder="blurred" layout="fullWidth" imgStyle={{ borderRadius: 6 }} />
        }
        subtitle={"Beauty & Glow, Together!"}
        title={`Private <b style="color:#e5aa4b;">SPA</b>rty`}
        description={`We are excited to have you and your squad party with us. We customize your event from start to finish, just let us know your vision and we'll make it happen. We accept any number of guests doing <b>10 Treatments</b> or more.<br/><br/> Please email us at <b><a href="mailto:keepcalm@essence-medspa.com">keepcalm@essence-medspa.com</a></b> or call us at <b><a href="tel://+17737631212">773 763-1212</a></b>`}
        ctaText1={"Call us"}
        ctaLink1={"+1 (773) 763-1212"}
        ctaText2={""}
        ctaLink2={""}
        video={""}
      />

      <ContentSectionLeft
        image={
          <StaticImage src={leftImage} alt="" placeholder="blurred" layout="fullWidth" imgStyle={{ borderRadius: 6 }} />
        }
        subtitle={"Gift Cards"}
        title={"A Perfect Gift to Someone Special!"}
        description={""}
        ctaText1={"Browse Collection"}
        ctaLink1={
          "https://www.vagaro.com/Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVGOwOvJkWSUu0HWpFHcIQKdziAzzXN6MkSKz4rU2l1PnM3MEg0m2hqPQWlqNqXcA14R4IqMe4FpU2ds9IcrYPL9zxOg7Q54n04kDy63+MfHDu99ov59H8Ij505cqCz81BQN8bMWMaAbHb1TtZMNcNNSD9cEnGtY8UzntIRtke/hIaN74IzpmrSgy1UUsr9IthvlgfvKwPpOVYw0Gn2GCeJ9yuXa9X+vS+kN1VJfSGxizZv7xHtZd+Ct3dpE9VpNzge6cI16+VNesvPxgK3VO+eoaNwXwze/CLbVYlz5t4BHEKmHwTiVfLUvozETgr+SQzQ=="
        }
        ctaText2={""}
        ctaLink2={""}
        video={""}
      />

      <ContentSectionRight
        image={
          <StaticImage
            src={rightImage}
            alt=""
            placeholder="blurred"
            layout="fullWidth"
            imgStyle={{ borderRadius: 6 }}
          />
        }
        subtitle={"It Starts Here"}
        title={"Top-quality providers. Fully-personalized recommendations."}
        description={
          "Essence provides the highest level of aesthetic, spa treatments, and wellness performed by Professionals in a refined and private setting."
        }
        ctaText1={"Book Now"}
        ctaLink1={
          "https://www.vagaro.com//Users/BusinessWidget.aspx?enc=MMLjhIwJMcwFQhXLL7ifVGOwOvJkWSUu0HWpFHcIQKdziAzzXN6MkSKz4rU2l1PnM3MEg0m2hqPQWlqNqXcA14R4IqMe4FpU2ds9IcrYPL/xxj7MYYxoWAvtCqNg1RXJX8kgtJTDR80t6tdV3sNL3RWzDv8TdOhBp+pVScNG/TsIfeEAvs5DkrjHF9r8X+YNpbuxsd6ejPV/OlSmhDJRpeYmx1w50xO1Owu3DYnNM3qdjxbeHfkuVwmO6NEUe+azOrQWW3vbh8rdH/Lg2XvNVAjucmJmKz5xpjehiAM8H1xtkKvO+HLuz+XCpYNiK/vMzvesh1ZjznIYOx72T/clCg=="
        }
        ctaText2={""}
        ctaLink2={""}
        video={""}
      />

      {/* <ServiceSection /> */}
      {/* <FeatureSection /> */}
      {/* <ContentSectionOne /> */}
      {/* <ContentSectionTwo /> */}
      {/* <TestimonialSection /> */}
      {/* <CounterSection /> */}
      {/* <FormSection /> */}
      {/* <TeamSection /> */}
      {/* <FooterOne /> */}
      {/* <TreatmentsSection /> */}
      <TestimonialSection reviews={googleReviewsRestructured} business={googleBusiness} />
      <CtaSection />
      <FooterBlock />
      <FooterSimple />
    </PageWrapper>
  )
}

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx: allMdx(sort: { fields: [frontmatter___title], order: DESC }) {
      nodes {
        id
        body
        fields {
          slug
        }
        excerpt(pruneLength: 250)
        frontmatter {
          type
          homepageSlider
          heroImage {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
          heroTag
          heroTitle
          category
          title
          description
          image
          cta1
          cta1Text
          cta2
          cta2Text
        }
      }
    }
    homepageSlider: allMdx(
      sort: { fields: [frontmatter___title], order: ASC }
      filter: { frontmatter: { homepageSlider: { eq: true }, status: { eq: true } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          type
          homepageSlider
          heroImage {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
          heroTag
          heroTitle
          category
          title
          description
          image
          cta1
          cta1Text
          cta2
          cta2Text
        }
      }
    }
    googleBusiness: allGooglePlacesPlace {
      edges {
        node {
          name
          rating
          user_ratings_total
          icon
          formatted_address
        }
      }
    }
    googleReviews: allGooglePlacesReview(filter: { rating: { eq: 5 } }, limit: 3) {
      edges {
        node {
          id
          author_url
          author_name
          profile_photo_url
          text
          rating
          time
        }
      }
    }
    # yelpReviews: allYelpBusinessReview(filter: { rating: { eq: 5 } }) {
    #   edges {
    #     node {
    #       id
    #       rating
    #       text
    #       time_created
    #       url
    #       user {
    #         name
    #         profile_url
    #       }
    #     }
    #   }
    # }
    yelpBusiness: allYelpBusinessDetails {
      edges {
        node {
          url
          name
          hours {
            is_open_now
          }
          rating
          review_count
          display_phone
          categories {
            title
          }
        }
      }
    }
  }
`
