import styled from "styled-components/macro"
import { Box, Heading, Button, Paragraph } from "~styled"

const Content = styled(Box)``

Content.Title = styled(Heading)`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
  position: relative;
  @media (min-width: 768px) {
    font-size: 38px;
  }
  @media (min-width: 992px) {
    font-size: 48px;
  }
`

Content.Subtitle = styled(Heading)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
`

Content.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3;
`

Content.ButtonGroup = styled(Box)`
  margin-right: -17.0656px;
  display: flex;
  flex-wrap: wrap;
`

Content.Image = styled(Box)`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 12px 40px 4px rgba(0, 0, 0, 0.15);
  &:before,
  &:after {
    border-radius: 8px;
    overflow: hidden;
  }
  .video-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: #feb856;
    border-color: transparent;

    min-width: 76px;
    max-width: 76px;
    min-height: 76px;
    max-height: 76px;
    @media (min-width: 575px) {
      min-width: 96px;
      max-width: 96px;
      min-height: 96px;
      max-height: 96px;
    }
  }
`

Content.Button = styled(Button)`
  margin-right: 15px;
  color: #fff;
  min-width: 140px;
  height: 56px;
  margin-top: 15px;
  font-size: 14px @media (min-width: 576px) {
    font-size: 15px;
  }
  @media (min-width: 1200px) {
    min-width: 165px;
    height: 60px;
  }
  &.btn-1 {
    box-shadow: none;
    &:hover {
      box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
      color: #fff;
    }
  }
  &.btn-2 {
    box-shadow: none;
    &:hover {
      box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
      color: #fff;
    }
  }
`

Content.Box = styled(Box)``

export default Content
