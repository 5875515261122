import styled from "styled-components/macro"
import { Box, Heading, Button, Paragraph } from "~styled"

const Intro = styled(Box)``

Intro.Title = styled(Heading)`
  font-weight: 500;
  letter-spacing: normal;
  position: relative;
  font-size: 32px;
  line-height: 1;
  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 1.2;
  }
  @media (min-width: 992px) {
    font-size: 48px;
    line-height: 1.4;
  }
`
Intro.Subtitle = styled(Heading)`
  font-weight: 500;
  letter-spacing: normal;
  font-size: 20px;
  line-height: 1.25;
  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 1.5;
  }
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 1.75;
  }
`
Intro.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.77777;
`
Intro.Button = styled(Button)`
  color: #fff;
  border-color: var(--bs-primary);
  background-color: var(--bs-primary);
  box-shadow: 0 20px 20px rgb(253 52 110 / 30%);
  border-radius: 500px;
  &:hover {
    box-shadow: 0 20px 20px rgb(253 52 110 / 0%);
    color: #fff;
  }
`

Intro.ContentTextBlock = styled(Box)`
  position: relative;
`
Intro.Image = styled(Box)`
  position: relative;
  width: 80%;
  padding-bottom: 20%;
  @media (min-width: 1200px) {
    width: 100%;
  }
`
Intro.ImageInner = styled(Box)`
  position: absolute;
  right: -25%;
  bottom: 0;
  width: 84%;
`

Intro.Box = styled(Box)``

export default Intro
