import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Intro from "./style"

export default function IntroSection({ ...rest }) {
  return (
    <Intro backgroundColor="black" {...rest}>
      <Container>
        <Row className="justify-content-center text-center">
          <Col xs="12" className="col-xxl-7 col-xl-8 px-sm-7 col-lg-9 col-md-10 col-sm-10">
            <Intro.Box mb="0" pb="0px">
              <Intro.Subtitle as="p" fontColor="#e5aa4b">
                Revitalize Your
              </Intro.Subtitle>
              <Intro.Title as="h2" pb="0px" className="" fontColor="#fff">
                Mind, Body, Soul
              </Intro.Title>
              <Intro.Subtitle as="p" fontColor="#e5aa4b">
                With Essence Med Spa Treatments
              </Intro.Subtitle>
            </Intro.Box>
          </Col>
        </Row>
      </Container>
    </Intro>
  )
}
